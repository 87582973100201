import { useEffect, useRef, useState } from "react";
import { View, Text, ScrollView, Animated, TouchableOpacity, useWindowDimensions, TextInput, ActivityIndicator, Image } from "react-native";
import { Entypo } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import { addCommande } from "../firebaseConfig";

const SecondeModal = ({ setShow, setPrivousShow, price, data, setQuantities }) => {

    const vertical = useRef(new Animated.Value(0)).current;
    const { height, width } = useWindowDimensions();    
    const cond = width < 900;

    useEffect(() => {
        Animated.spring(vertical, {
            toValue: 1,
            speed: 5
        }).start()
    }, []);

    const [nom, setNom] = useState("");
    const [loading, setLoading] = useState(false);

    return (
        <View style={{ height: "100vh", width: "100vw", backgroundColor: "rgba(242, 242, 242, 0.75)", position: "fixed", zIndex: 1, justifyContent: "center", alignItems: "center", top: 0, left: 0 }}>
            <Animated.View
                style={{
                    maxHeight: "60vh",
                    width: "60vw",
                    minWidth: cond ? "calc(100vw - 40px)" : 600,
                    backgroundColor: "rgba(242, 242, 242, 1)",
                    borderRadius: 10,
                    transform: [{
                        translateY:
                            vertical.interpolate({
                                inputRange: [0, 1],
                                outputRange: [-height, 0]
                            })
                    }],
                    shadowOffset: {
                        width: 0,
                        height: 0,
                    },
                    shadowOpacity: 0.4,
                    shadowRadius: 5,
                    padding: 20,
                }}
            >
                <View style={{ flexDirection: "row", justifyContent: "space-between" }} >
                    <Text style={{ fontSize: 40, fontWeight: "700" }}>Votre nom :</Text>
                    <TouchableOpacity onPress={() => setShow(false)}>
                        <Entypo name="cross" size={50} color="black" />
                    </TouchableOpacity>
                </View>
                <View>
                    <TextInput style={{ height: 50, borderColor: "black", borderWidth: 1, borderRadius: 10, outline: "none", paddingLeft: 10, fontSize: 24, marginTop: 20 }} value={nom} onChange={(e) => setNom(e.nativeEvent.text)} />
                    <Text style={{ marginVertical: 20 }}>La somme de {price} € sera à payer au comptoir lors de la récéption de votre commande.</Text>
                    <TouchableOpacity disabled={loading} onPress={async () => {
                        if (nom.length <= 1) {
                            return alert("Entrez un nom valide.");
                        }
                        setLoading(true);
                        const err = await addCommande(data, price, nom);
                        if (err == null) {
                            alert("Commande passée avec succès !");
                        } else {
                            alert("Erreur lors du passage de la commande.")
                        }
                        setQuantities({});
                        setShow(false);
                        setPrivousShow(false);

                    }} style={{ backgroundColor: "black", padding: 10, borderRadius: 10, width: "auto", justifyContent: "center", alignItems: "center", flexDirection: "row" }} >
                        <Text style={{ color: "white", fontSize: 25, fontWeight: "600" }}>Passer commande</Text>
                        {
                            loading ? <ActivityIndicator color={"white"} size={"small"} style={{ marginLeft: 20 }} /> : null
                        }
                    </TouchableOpacity>
                </View>
            </Animated.View>
        </View>
    )
}

const Modal = ({ data, setShow, setQuantities }) => {

    const vertical = useRef(new Animated.Value(0)).current;
    const { height } = useWindowDimensions();
    const [total, setTotal] = useState(0);

    const { width } = useWindowDimensions();
    const cond = width < 900;

    useEffect(() => {
        var t = 0;

        for (let i = 0; i < data.length; i++) {
            t += Number(data[i].price) * data[i].quantity;
        }

        setTotal(t.toFixed(2));

        Animated.spring(vertical, {
            toValue: 1,
            speed: 5
        }).start()
    }, []);

    const [showModal, setShowModal] = useState(false);

    return (
        <View style={{ height: "100vh", width: "100vw", backgroundColor: "rgba(242, 242, 242, 0.75)", position: "fixed", zIndex: 1, justifyContent: "center", alignItems: "center", top: 0, left: 0 }}>
            {
                showModal ? <SecondeModal setPrivousShow={setShow} setShow={setShowModal} price={total} data={data} setQuantities={setQuantities} /> : null
            }
            <Animated.View
                style={{
                    height: "60vh",
                    width: "60vw",
                    minWidth: cond ? "calc(100vw - 40px)" : 600,
                    backgroundColor: "rgba(242, 242, 242, 1)",
                    borderRadius: 10,
                    transform: [{
                        translateY:
                            vertical.interpolate({
                                inputRange: [0, 1],
                                outputRange: [-height, 0]
                            })
                    }],
                    shadowOffset: {
                        width: 0,
                        height: 0,
                    },
                    shadowOpacity: 0.4,
                    shadowRadius: 5,
                    padding: 20
                }}
            >
                <View style={{ flexDirection: "row", justifyContent: "space-between" }} >
                    <Text style={{ fontSize: cond ? 30 : 40, fontWeight: "700" }}>Ma commande :</Text>
                    <TouchableOpacity onPress={() => setShow(false)}>
                        <Entypo name="cross" size={cond ? 40 : 50} color="black" />
                    </TouchableOpacity>
                </View>
                <ScrollView style={{ height: "calc(100% - 40px)", marginTop: 20 }}>
                    {
                        data.map((el, i) => {
                            return (
                                // <View key={i} style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 15 }}>
                                <View key={i}>
                                    <Text style={{ fontSize: 25, fontWeight: "600" }}>{el.name}</Text>
                                    <Text>{el.description}</Text>
                                    <View style={{ flexDirection: "row" }}>
                                        <View style={{ height: 10, width: cond ? "calc(100vw - 80px - 170px)" : "calc(600px - 40px - 170px)", borderBottomLeftRadius: 10, borderBottomColor: "black", borderLeftColor: "black", borderRightColor: "rgb(242, 242, 242)", borderTopColor: "rgb(242, 242, 242)", borderWidth: 1, marginLeft: 10, marginTop: 2 }} />
                                        <Text style={{ fontSize: 16, fontWeight: "600", marginLeft: 15 }}>{el.quantity} x {el.price}€</Text>
                                    </View>
                                </View>                                    
                                // </View>
                            )
                        })
                    }
                </ScrollView>
                <View style={{ flexDirection: cond ? "column" : "row", justifyContent: "space-between", paddingTop: 15, alignItems: cond ? "null" :"center" }}>
                    <Text style={{ fontSize: 25, fontWeight: "700" }}>Total : {total} €</Text>
                    <TouchableOpacity style={{ backgroundColor: "black", padding: 10, borderRadius: 10, justifyContent: "center", alignItems: "center", marginTop: cond ? 15 : 0 }}
                        onPress={() => {
                            setShowModal(true);
                        }}
                    >
                        <Text style={{ color: "white", fontSize: 25, fontWeight: "600" }}>Passer commande</Text>
                    </TouchableOpacity>
                </View>

            </Animated.View>
        </View>
    )
}

export const ClickAndCollect = () => {

    const data = [
        ["Nos formules", require("../assets/wrap.jpeg")],
        { title: "Formule plat du jour", description: "Plat du jour + Boisson + Dessert", price: "15.50 " },
        { title: "Formule salade", description: "Salade + Boisson + Dessert", price: "12.00 " },
        { title: "Formule slalade premium", description: "Salade premium + Boisson + Dessert", price: "14.00 " },
        { title: "Formule focaccia", description: "Focaccia + Boisson + Dessert", price: "9.40 " },
        { title: "Formule poke bowl", description: "Poke bowl + Boisson + Dessert", price: "17.40 " },
        ["Les pokés bowls", require("../assets/bowl2.jpeg")],
        { title: "Poke bowl saumon teriyaki", description: "Saumon cru, riz blanc vinaigré, avocat, choux, carottes, cebettes, coriandre et graines de sésames", numberLine: 2, price: "12.90 " },
        { title: "Poke bowl exotique", description: "Saumon cru, fruit de la passion, carottes au jus de yuzu, edamames, concombres et oignons", price: "13.00 " },
        { title: "Poke bowl falafel", description: "Falafels, riz vinaigré, avocat, endamames, choux blanc mariné, tomates cerises", price: "13.00 " },
        ["Les desserts", ""],
        { title: "test", description: "test", price: "test" },
        { title: "test", description: "test", price: "test" },
        { title: "test", description: "test", price: "test" },
        { title: "test", description: "test", price: "test" },
        { title: "test", description: "test", price: "test" },
    ];

    const [showModal, setShowModal] = useState(false);
    const [showSuivant, setShowSuivant] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [cart, setCart] = useState({});

    const navigation = useNavigation();

    useEffect(() => {
        const check = () => {
            const vals = Object.values(quantities);
            vals.sort()
            if (vals[vals.length - 1]) {
                setShowSuivant(true);
            } else {
                setShowSuivant(false);
            }
        }

        check();
    }, [quantities]);

    const { width } = useWindowDimensions();
    const cond = width < 900;

    return (
        <View style={{ width: "100vw", justifyContent: "center", alignItems: "center", backgroundColor: "white", paddingBottom: 40, minHeight: "100vh" }}>
            <TouchableOpacity style={{ position: "absolute", top: 10, right: 25, zIndex: 1 }} onPress={() => navigation.goBack()} >
                <AntDesign name="close" size={40} color="black" />
            </TouchableOpacity>
            {
                showModal ? <Modal data={cart} setShow={setShowModal} setQuantities={setQuantities} /> : null
            }
            <Text style={{ fontSize: cond ? "9vw" : "6vw", fontWeight: "700", textAlign: "center", marginTop: 50 }} >Click and collect</Text>
            <Text style={{ fontSize: 20, textAlign: cond ? "center" : "null", paddingHorizontal: cond ? 20: 0 }}>Passez commande en ligne puis récupérez et payez vos produits sur place !</Text>
            {
                showSuivant ?
                    <TouchableOpacity
                        style={{
                            backgroundColor: "black", padding: 10, borderRadius: 10, marginTop: 30, position: "fixed", zIndex: showModal ? 0 : 1, bottom: cond ? 30 : 60, right: cond ? 40 : "calc(50vw - 300px + 10px)", shadowColor: "#000",
                            shadowOffset: {
                                width: 0,
                                height: 0,
                            },
                            shadowOpacity: 0.29,
                            shadowRadius: 4.65,
                        }}
                        onPress={() => {
                            var res = [];
                            for (let i = 0; i < Object.keys(quantities).length; i++) {
                                const key = Object.keys(quantities)[i];
                                const keyBis = Object.keys(data)[key];

                                if (quantities[key] > 0) {
                                    res.push({ quantity: quantities[key], name: data[keyBis].title, description: data[keyBis].description, price: data[keyBis].price });
                                }
                            }
                            if (res.length == 0) {
                                return alert("Votre panier ne peut pas être vide pour continuer.")
                            }

                            setShowModal(true);
                            setCart(res);
                        }}
                    >
                        <Text style={{ color: "white", fontSize: 25, fontWeight: "600" }}>Suivant</Text>
                    </TouchableOpacity>
                    : null
            }

            <ScrollView style={{ height: cond ? "calc(100vh - 135px - 6vw - 50px)" : "calc(100vh - 135px - 6vw - 50px)", width: "100vw", maxWidth: 600, borderWidth: cond ? 0 : 1, borderColor: "black", borderRadius: 10, paddingLeft: 20, paddingBottom: cond ? 0 : 10, marginTop: cond ? 0 : 30 }} stickyHeaderIndices={[0, 6, 10]}>
                {
                    data.map((el, i) => {
                        // const [quantity, setQuantity] = useState(0);                        
                        if (el.length != 2) {
                            return (
                                <View key={i} style={{ marginBottom: cond ? 0 : 20, flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingRight: 20 }}>
                                    <View style={{maxWidth: cond  ? "calc(100vw - 40px - 110px)" : "auto"}}>
                                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                                            <Text style={{ fontSize: 24, fontWeight: "600" }}>{el.title} <Text style={{ fontSize: 18, color: "gray" }} numberOfLines={1}>{el.price}€</Text></Text>
                                            {/* <Text style={{ fontSize: 18, marginLeft: 12 }} numberOfLines={1} >{el.price}€</Text> */}
                                        </View>
                                        <Text style={{ fontSize: 20 }}>{String(el.description).substring(0, 50)}</Text>
                                    </View>
                                    <View style={{ flexDirection: "row" }}>
                                        <TouchableOpacity onPress={() => {
                                            if (quantities[i] > 0) {
                                                setQuantities({ ...quantities, [i]: quantities[i] - 1 });
                                            }
                                        }}>
                                            <Entypo name="minus" size={ cond ? 18 : 20} color="black" />
                                        </TouchableOpacity>
                                        <Text style={{ marginHorizontal: 10, fontSize: cond ? 18 : 20, fontWeight: "600" }} >{quantities[i] ? quantities[i] : 0}</Text>
                                        <TouchableOpacity onPress={() => {
                                            if (!quantities[i]) {
                                                setQuantities({ ...quantities, [i]: 1 });
                                            } else {
                                                setQuantities({ ...quantities, [i]: quantities[i] + 1 });
                                            }
                                        }}>
                                            <Entypo name="plus" size={cond ? 18 : 20} color="black" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                        }
                        return (
                            <View key={i} style={{ backgroundColor: true ? "white": "rgb(242, 242, 242)", paddingVertical: 20 }}>
                                <Text style={{ fontSize: cond ? 34 : 40, fontWeight: "800" }}>{el[0]}</Text>
                            </View>
                        )
                    })
                }
            </ScrollView>
        </View>
    )
}