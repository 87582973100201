// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, addDoc } from "firebase/firestore";

// const firebaseConfig = {
//     apiKey: "AIzaSyCYUkX1yJkHfIMWUBzS35vQ63j_Q4m0a3Q",
//     authDomain: "midiohalles-3a2e5.firebaseapp.com",
//     projectId: "midiohalles-3a2e5",
//     storageBucket: "midiohalles-3a2e5.appspot.com",
//     messagingSenderId: "912554267233",
//     appId: "1:912554267233:web:2939ad249d6e4b41419020"
// };

// const app = initializeApp(firebaseConfig);

// const db = getFirestore(app);

// export const addCommande = async (data, prix, nom) => {
//     try {
//         const docRef = await addDoc(collection(db, "commandes"), {
//             plats: data,
//             prix: prix,
//             date: Date.now(),
//             nom: nom,
//             finie: false,
//             paid: false
//           });
//           console.log("Document written with ID: ", docRef.id);
//     } catch (e) {
//         console.error("Error adding document: ", e);
//     }
    
// }

import { createClient } from "@supabase/supabase-js";

const url = "https://ttibczvpajlclubshgvf.supabase.co";
const key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InR0aWJjenZwYWpsY2x1YnNoZ3ZmIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4MzAyODQyMCwiZXhwIjoxOTk4NjA0NDIwfQ.BWYxmrE4pJsM38Sog8WGSVjusKmmCmvwpyZ3DmptcyM";

const supabase = createClient(url, key);

export const addCommande = async (data, prix, nom) => {
    const { error } = await supabase.from("commandes").insert({
        plats: data,
        prix: prix,
        nom: nom
    });

    await supabase.functions.invoke("notif", {body: {
        "name": nom,
        "price": prix,
        "num": data.length,
    }});

    return error;
}