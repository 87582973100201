import { useEffect, useRef, useState } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView, useWindowDimensions, Animated } from "react-native";
import { NavBar } from "./components/NavBar";
import { Entypo } from '@expo/vector-icons';
import { StatusBar } from "expo-status-bar";
import Carousel from "react-native-reanimated-carousel";

const CarouselCustom = () => {

    const useInterval = (callback, delay) => {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            let id = setInterval(() => {
                savedCallback.current();
            }, delay);
            return () => clearInterval(id);
        }, [delay]);
    }

    const data = [
        require("../assets/1.jpeg"),
        require("../assets/2.jpeg"),
        require("../assets/3.jpeg"),
    ];

    const [index, setIndex] = useState(0);

    useInterval(() => {
        setIndex((index + 1) % data.length);
    }, 2500);

    return (
        <View style={{ marginTop: 50, alignItems: "center", width: "100vw" }}>
            <View style={{ height: 500, width: 500, maxHeight: "calc(100vw - 40px)", maxWidth: "calc(100vw - 40xpx)" }} />
            {/* {
                data.map((el, i) => {
                    return (
                        <Image key={i} style={{ height: 500, width: 500, maxHeight: "calc(100vw - 40px)", maxWidth: "calc(100vw - 40px)", borderRadius: 10, position: "absolute", zIndex: index == i ? 1 : 0 }} resizeMode="cover" source={el} />
                    )                    
                })
            }
            <View style={{ flexDirection: "row", marginTop: 20 }}>
                {
                    data.map((el, i) => {
                        return (
                            <TouchableOpacity key={i} style={{ height: 12, width: 12, backgroundColor: index == i ? "black" : "gray", borderRadius: 10, marginRight: 10 }} onPress={() => setIndex(i)} />
                        )
                    })
                } */}
            {/* </View> */}
        </View>
    )
}

export const HomePage = () => {

    const [restau, setRestau] = useState(true);
    const ref = useRef(0);
    const { height, width } = useWindowDimensions();

    const cond = width < 900;
    const carouselWidth = width > 460 ? 420 : width - 40;

    const data = {
        "Nos formules": {
            image: require("../assets/wrap.jpeg"), plats: [
                { title: "Formule plat du jour", description: "Plat du jour + Boisson + Dessert", price: "15.50€" },
                { title: "Formule salade", description: "Salade + Boisson + Dessert", price: "12.00€" },
                { title: "Formule slalade premium", description: "Salade premium + Boisson + Dessert", price: "14.00€" },
                { title: "Formule focaccia", description: "Focaccia + Boisson + Dessert", price: "9.40€" },
                { title: "Formule poke bowl", description: "Poke bowl + Boisson + Dessert", price: "17.40€" },
            ]
        },
        "Les pokés bowls": {
            image: require("../assets/bowl2.jpeg"), plats: [
                { title: "Poke bowl saumon teriyaki", description: "Saumon cru, riz blanc vinaigré, avocat, choux, carottes, cebettes, coriandre et graines de sésames", numberLine: 2, price: "12.90€" },
                { title: "Poke bowl exotique", description: "Saumon cru, fruit de la passion, carottes au jus de yuzu, edamames, concombres et oignons", price: "13.00€" },
                { title: "Poke bowl falafel", description: "Falafels, riz vinaigré, avocat, endamames, choux blanc mariné, tomates cerises", price: "13.00€" },
            ]
        },
        "Les plats chauds": { image: "", plats: [] },
        "Les salades": { image: "", plats: [] },
        "Les salades premium": { image: "", plats: [] },
        "Les foccacias": { image: "", plats: [] },
        "Les sandwichs": { image: "", plats: [] },
        "Nos délicieux desserts": { image: "", plats: [] }
    }

    const images = [
        require("../assets/1.jpeg"),
        require("../assets/2.jpeg"),
        require("../assets/3.jpeg"),
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <ScrollView ref={ref} style={{ height: "100vh" }}>
            <StatusBar backgroundColor="rgb(242, 242, 242)" />
            <NavBar />
            {/* first screen */}
            <View
                style={{
                    flexDirection: cond ? "column" : "row",
                    width: "100vw",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minHeight: cond ? "auto" : "100vh",
                }}
            >
                <View style={{ width: cond ? "100vw" : "50vw", justifyContent: "center", alignItems: "center", marginTop: cond ? 100 : 0 }}>
                    <Image source={require("../assets/logo.png")} style={{ height: cond ? "50vw" : "calc(30vw * 389/676)", width: cond ? "50vw" : "30vw" }} resizeMode="contain" />
                    <Text style={{ fontSize: 22, fontWeight: "500", marginTop: 20 }} >Restauration le midi et bar le soir</Text>
                    <View style={{ flexDirection: "row", marginTop: 20 }}>
                        <TouchableOpacity
                            onPress={() => {
                                setRestau(true);
                                ref.current.scrollTo({ y: height });
                            }}
                            style={{
                                alignItems: "center"
                            }}
                        >
                            <Text style={{ fontSize: 20, fontWeight: "500" }}>Restaurant</Text>
                            <View style={{ height: 10, width: 10, backgroundColor: restau ? "black" : "transparent", borderRadius: 10, marginTop: 3 }} />
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                setRestau(false);
                                ref.current.scrollTo({ y: height });
                            }}
                            style={{
                                alignItems: "center",
                                marginLeft: 20,
                            }}
                        >
                            <Text style={{ fontSize: 20, fontWeight: "500" }}>Bar</Text>
                            <View style={{ height: 10, width: 10, backgroundColor: restau ? "transparent" : "black", borderRadius: 10, marginTop: 3 }} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ width: cond ? "100vw" : "50vw", paddingHorizontal: cond ? 0 : 75 / 2, }}>
                    <Image source={require(cond ? "../assets/bowl-half.png" : "../assets/bowl.png")} style={{ height: cond ? "50vw" : "calc(50vw - 75px)", width: cond ? "100vw" : "calc(50vw - 75px)", marginTop: cond ? 20 : 0 }} />
                </View>
            </View>
            {/* second screen */}
            <View style={{ minHeight: cond ? "auto" : "100vh" }} >
                <Text style={{ marginTop: cond ? 30 : 0, fontSize: cond ? "10vw" : "6vw", fontWeight: "700", marginLeft: 20 }}>{restau ? "Le Restaurant" : "Le Bar"}</Text>
                {
                    restau ?
                        <View>
                            <ScrollView horizontal={true} style={{ marginLeft: 20, marginTop: 10 }} showsHorizontalScrollIndicator={false}>
                                {
                                    Object.keys(data).map((el, i) => {
                                        return (
                                            <TouchableOpacity key={i} style={{ padding: 14, borderRadius: 10, backgroundColor: currentIndex == i ? "black" : "transparent", marginRight: 10, borderWidth: currentIndex == i ? 0 : 1 }}
                                                onPress={() => setCurrentIndex(i)}
                                            >
                                                <Text style={{ color: currentIndex == i ? "white" : "black", fontSize: 16, fontWeight: "500" }}>{el}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </ScrollView>
                            <View style={{ marginTop: 20, marginHorizontal: 20, flexDirection: "row" }}>
                                {
                                    cond ? null : <Image source={data[Object.keys(data)[currentIndex]].image} style={{ alignSelf: "flex-end", height: "calc(100vh - 6vw - 110px)", width: "calc((100vh - 6vw - 110px) * 0.8)", maxWidth: "30vw", borderRadius: 10 }} />
                                }
                                {
                                    cond ? <View>
                                        {
                                            data[Object.keys(data)[currentIndex]].plats.map((el, i) => {
                                                return (
                                                    <View key={i} style={{ flexDirection: "row", alignItems: "center", marginBottom: 30, width: "100vw", justifyContent: "space-between" }}>
                                                        <View style={{ flexDirection: "column" }}>
                                                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                                <Text style={{ fontSize: 26, fontWeight: "700", maxWidth: "calc(100vw - 40px - 150px)" }}>{el.title}</Text>
                                                            </View>
                                                            <Text style={{ color: "gray", fontSize: 18, fontWeight: "400", maxWidth: "calc(100vw - 40px - 150px)" }} >{el.description}</Text>
                                                        </View>
                                                        <Text style={{ fontSize: 18, marginRight: 40 }} >{el.price}</Text>
                                                    </View>
                                                )
                                            })
                                        }
                                    </View> :
                                        <ScrollView style={{ height: "calc(100vh - 6vw - 110px)", marginLeft: 20, paddingTop: 10 }} >
                                            {
                                                data[Object.keys(data)[currentIndex]].plats.map((el, i) => {
                                                    return (
                                                        <View key={i} style={{ flexDirection: "row", alignItems: "center", marginBottom: 30 }}>
                                                            <View>
                                                                <Text style={{ fontSize: 26, fontWeight: "700" }}>{el.title}</Text>
                                                                <Text style={{ color: "gray", fontSize: 18, fontWeight: "400" }} >{el.description}</Text>
                                                            </View>
                                                            <View style={{ padding: 8, borderRadius: 10, backgroundColor: "black", marginLeft: 20 }} >
                                                                <Text style={{ color: "white", fontWeight: "600", fontSize: 16 }} >{el.price}</Text>
                                                            </View>
                                                        </View>
                                                    )
                                                })
                                            }
                                        </ScrollView>
                                }
                            </View>
                        </View>
                        : null
                }
            </View>
            {/* third screen */}
            <View style={{ width: "calc(100vw - 40px)", marginLeft: 20, height: 5, backgroundColor: "black", borderRadius: 5 }} />
            {/* <Carousel /> */}
            <View style={{width: "100vw", justifyContent: "center", alignItems: "center", marginTop: 50 }}>
                <Carousel
                    loop
                    width={carouselWidth}
                    height={carouselWidth}
                    autoPlay={true}
                    data={images}
                    scrollAnimationDuration={2000}                    
                    renderItem={({ index }) => (
                        <View
                            style={{
                                height: carouselWidth,
                                width: carouselWidth,
                                justifyContent: 'center',
                                alignItems: "center"
                            }}
                        >
                            <Image source={images[index]} style={{ width: carouselWidth - 20, height: carouselWidth - 20, borderRadius: 10 }} />
                        </View>
                    )}
                />
            </View>
            {/* fourth screen */}
            <View style={{ minHeight: cond ? "110vh" : "100vh", width: "100vw" }}>
                <View style={{ width: "100vw" }} >
                    <Text style={{ fontSize: cond ? "10vw" : "6vw", fontWeight: "700", textAlign: "center", marginTop: 50 }} >Nous trouver</Text>
                    <Image source={require("../assets/arrow.png")} style={{ height: cond ? "15vw" : "10vw", width: cond ? "15vw" : "10vw", alignSelf: "center", transform: cond ? "translateX(40vw) translateY(-6vw)" : "translateX(25vw) translateY(-6vw)" }} resizeMode="contain" />
                </View>
                <View style={{ marginTop: cond ? 40 : 0, flexDirection: cond ? "column" : "row", width: "100vw", paddingHorizontal: "10vw", justifyContent: "space-around", height: "calc(100vh - 5px - 18vw - 200px)", alignItems: "center" }} >
                    <View style={{
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 5,
                        padding: 20,
                        borderRadius: 10,
                        alignItems: "center"
                    }}>
                        <View style={{ flexDirection: "row" }}>
                            <Entypo name="location-pin" size={30} color="black" style={{ marginRight: 10 }} />
                            <Text style={{ fontSize: 26, fontWeight: "600" }}>Adresse</Text>
                        </View>
                        <Text style={{ marginVertical: 15, fontSize: 16 }}>11 Pl. Saint-Rémy,{'\n'}10000 Troyes</Text>
                        <TouchableOpacity style={{ padding: 10, borderRadius: 10, backgroundColor: "black", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ color: "white", fontWeight: "500", fontSize: 16 }}>Ouvrir dans maps</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 5,
                        padding: 20,
                        borderRadius: 10,
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginVertical: cond ? 30 : 0
                    }}>
                        <View style={{ flexDirection: "row" }}>
                            <Entypo name="phone" size={30} color="black" style={{ marginRight: 10 }} />
                            <Text style={{ fontSize: 26, fontWeight: "600" }}>Téléphone</Text>
                        </View>
                        <Text style={{ fontSize: 16, marginVertical: 15 }}>03 25 73 69 09</Text>
                        <TouchableOpacity style={{ padding: 10, borderRadius: 10, backgroundColor: "black", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ color: "white", fontWeight: "500", fontSize: 16 }}>Appeler</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 5,
                        padding: 20,
                        borderRadius: 10,
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <View style={{ flexDirection: "row" }}>
                            <Entypo name="shopping-bag" size={30} color="black" style={{ marginRight: 10 }} />
                            <Text style={{ fontSize: 26, fontWeight: "600" }}>Commander</Text>
                        </View>
                        <TouchableOpacity onPress={() => {
                            window.open("https://www.ubereats.com/fr/store/midi-o-halles-le-corner-gourmand/Rhks9-DhSZasolz2QiJqgA?utm_campaign=place-action-link&utm_medium=organic&utm_source=google", "_blank").focus();
                        }} style={{ padding: 10, borderRadius: 10, backgroundColor: "black", justifyContent: "center", alignItems: "center", marginVertical: 15 }}>
                            <Text style={{ color: "white", fontWeight: "500", fontSize: 16 }}>Uber Eats</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            window.open("https://deliveroo.fr/en/menu/troyes/troyes-centre-ville/midi-o-halles?utm_medium=affiliate&utm_source=google_maps_link", '_blank').focus();
                        }} style={{ padding: 10, borderRadius: 10, backgroundColor: "black", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ color: "white", fontWeight: "500", fontSize: 16 }}>Deliveroo</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </ScrollView>
    )
}