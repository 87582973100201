import { View, Text, TouchableOpacity, Image, useWindowDimensions } from "react-native";
import { useNavigation } from '@react-navigation/native';

export const NavBar = () => {

    const navigation = useNavigation();

    const { width } = useWindowDimensions()
    const cond = width < 900;

    return (
        <View 
            style={{
                position: "absolute",
                width: "100vw",
                flexDirection: "row",
                zIndex: 1,
                height: 70, 
                padding: 10,
                paddingLeft: 20,
                alignItems: "center", 
                justifyContent: cond ? "space-between" : "none"
            }}
        >
            <TouchableOpacity>
                <Image source={require("../../assets/logo.png")} style={{height: cond ? 80 : 50, width: cond ? 80 : 50}} resizeMode="contain" />
            </TouchableOpacity>
            <View style={{ flexDirection: "row" }}>
                <TouchableOpacity 
                    style={{ marginLeft: 25}} 
                    onPress={() => {
                        navigation.navigate("ClickAndCollect")
                    }}
                >
                    <Text style={{ fontSize: cond ? 20: 18 }}>Click and collect</Text>
                </TouchableOpacity>            
                <TouchableOpacity 
                    style={{ marginLeft: 20, marginRight: cond ? 20 : 0 }} 
                    onPress={() => {
                        navigation.navigate("Postuler")
                    }}
                >
                    <Text style={{ fontSize: cond ? 20 : 18 }}>Postuler</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}