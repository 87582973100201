import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HomePage } from "./src/HomePage";
import { NavigationContainer } from "@react-navigation/native";
import { Postuler } from "./src/Postuler";
import { ClickAndCollect } from "./src/ClickAndCollect";

// Akeki68Jufr8xGwv

const Stack = createNativeStackNavigator();

const App = () => {
    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName="Home">
                <Stack.Screen name="Home" component={HomePage} options={{ headerShown: false }} />
                <Stack.Screen
                    name="Postuler"
                    component={Postuler}
                    options={{
                        headerShown: false,
                    }} />
                <Stack.Screen name="ClickAndCollect" component={ClickAndCollect} options={{ headerShown: false }} />
            </Stack.Navigator>
        </NavigationContainer>
    )
}

export default App;