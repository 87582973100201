import { useState } from "react";
import { View, Text, TouchableOpacity, TextInput, Image, useWindowDimensions } from "react-native";
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import * as DocumentPicker from 'expo-document-picker';

export const Postuler = () => {

    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [fileName, setFileName] = useState("");

    const navigation = useNavigation();

    const {width} = useWindowDimensions();
    const cond = width < 900;

    return (
        <View style={{width: "100vw", minHeight: "100vh"}}>
            <TouchableOpacity style={{ position: "absolute", top: 25, right: 25, zIndex: 1 }} onPress={() => navigation.goBack()} >
                <AntDesign name="close" size={40} color="black" />
            </TouchableOpacity>
            <Image source={require("../assets/logo.png")} style={{height: "20vw", width: "20vw", alignSelf: "center"}} resizeMode="contain" />
            <View style={{ alignSelf: "center", borderWidth: 1, borderColor: "black", borderRadius: 10, paddingTop: 20, paddingHorizontal: 20, minWidth: "50vw", marginTop: cond ? "20vh" : 0 }} >
                <Text style={{ fontWeight: "700", fontSize: 40, marginBottom: 20 }}>Nous recrutons !</Text>
                <View style={{ flexDirection: "row" }}>
                    <View style={{width: "50%"}} >
                        <Text>Nom</Text>
                        <TextInput onChange={(e) => setLastname(e.nativeEvent.text)} value={lastname} 
                            style={{ 
                                padding: 15, 
                                maxWidth: "calc(100% - 40px)",
                                borderRadius: 10,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 0,
                                },
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                elevation: 5,  
                                outline: "none",
                                marginTop: 6                    
                            }} 
                        />
                        <Text style={{ marginTop: 20 }}>Prénom</Text>
                        <TextInput onChange={(e) => setName(e.nativeEvent.text)} value={name} 
                            style={{ 
                                padding: 15, 
                                maxWidth: "calc(100% - 40px)",
                                borderRadius: 10,
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 0,
                                },
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                elevation: 5,  
                                outline: "none",
                                marginTop: 6                          
                            }} 
                        />
                    </View>
                    <View style={{width: "50%"}}>
                        <Text>Déposer mon CV</Text>
                        {
                            fileName ? 
                            <View style={{  marginTop: 30, justifyContent: "center", alignItems: "center" }} >
                                <Text>Votre fichier : {fileName.length > 11 ? fileName.slice(0, 5) + "..." + fileName.slice(fileName.length - 6, fileName.length) : fileName}</Text> 
                                <TouchableOpacity
                                    onPress={() => {
                                        DocumentPicker.getDocumentAsync({ multiple: false, type: "application/pdf" }).then((e) => {
                                            if (e.type == "success") {
                                                setFileName(e.file.name);
                                            } else {
                                                setFileName("");
                                            }
                                        })
                                    }}
                                    style={{
                                        padding: 14, borderRadius: 10, backgroundColor: "black", justifyContent: "center", alignItems: "center", maxWidth: 200, alignSelf: "center", marginTop: 10
                                    }}
                                >
                                    <Text style={{ fontWeight: "500", color: "white" }}>Modifier</Text>
                                </TouchableOpacity>
                            </View>
                            : 
                            <TouchableOpacity onPress={() => {
                                DocumentPicker.getDocumentAsync({ multiple: false, type: "application/pdf" }).then((e) => {                                    
                                    if (e.type == "success") {
                                        setFileName(e.file.name);
                                    } else {
                                        setFileName("");
                                    }
                                });
                            }} 
                                style={{
                                    padding: 14, borderRadius: 10, backgroundColor: "black", justifyContent: "center", alignItems: "center", maxWidth: 200, alignSelf: "center", marginTop: 30
                                }}
                            >
                                <Text style={{ color: "white", fontWeight: "500" }} >Ajouter un fichier (pdf)</Text>
                            </TouchableOpacity>
                        }
                    </View>
                </View>
                <TouchableOpacity onPress={() => {
                    if (name.length <= 1) {
                        alert("Vous devez entrer votre prénom.");
                    } else if (lastname.length <= 1) {
                        alert("Vous devez entrer votre nom.");
                    } else if (!fileName) {
                        alert("Ajoutez un CV en fichier pdf.")
                    } else {
                        alert("Envoyé !");
                    }
                }} activeOpacity={0.9} style={{ padding: 14, borderRadius: 10, backgroundColor: "black", height: 46, transform: "translateY(23px)", display: "flex", justifyContent: "center", alignItems: "center", maxWidth: 300, alignSelf: "center" }}>
                    <Text style={{ fontSize: 16, color: "white", fontWeight: "500"  }}>Envoyer ma candidature</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}